import React, { useState } from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1, H2, H3, H4 } from '../../components/Headings'
import Container from '../../components/Container'
import Img from 'gatsby-image'
import { ButtonLink } from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import HomeLayerItem from '../../components/HomeLayerItem'

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.nodes[0]

  const {
    hero,
    homeLayers: [roof, attic, houseWalls, basement],
    footerCta,
  } = page.homeExplorer

  return (
    <>
      <SEO />
      <Layout>
        <section>
          <Container tw="mb-20">
            <div tw="py-10 lg:(w-2/3)">
              <H1 tw="lg:(w-3/4)">{hero.headline}</H1>
              <p tw="text-3xl text-gray-700">{hero.paragraph}</p>
            </div>
          </Container>
        </section>

        {/* diagrams */}
        <Container>
          <HomeLayerItem tw="py-10 border-b-2 border-gray-50" {...roof} />
          <HomeLayerItem tw="py-10 border-b-2 border-gray-50" {...attic} />
          <HomeLayerItem tw="py-10 border-b-2 border-gray-50" {...houseWalls} />
          <HomeLayerItem tw="py-10" {...basement} />
        </Container>

        {/* Footer CTA */}
        <section tw="py-16" aria-label="Get Your Free Estimate">
          <Container>
            {/* Footer CTA */}
            <div tw="text-center content-center pt-10">
              <H2 tw="text-5xl">{footerCta.headline}</H2>
              <p tw="text-lg leading-relaxed text-gray-800 lg:(px-10 mx-10) xl:(px-24 mx-24) mb-8">
                {footerCta.subtext}
              </p>

              <ButtonLink to="/quote" large>
                <span tw="mr-3">{footerCta.ctaButtonText}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </ButtonLink>
            </div>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        id
        content
        homeExplorer {
          hero: homeExplorerHero {
            headline: homeExplorerHeroHeadline
            paragraph: homeExplorerHeroParagraph
          }
          homeLayers: homeExplorerHomeLayers {
            label: homeExplorerHomeLayerLabel
            graphic: homeExplorerGraphic {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  fixed(width: 800) {
                    aspectRatio
                  }
                }
              }
            }
            descriptionItems: homeExplorerDescriptionItems {
              headline: homeExplorerDescriptionHeadline
              paragraph: homeExplorerDescriptionParagraph
              xPos: descriptionXPosition
              yPos: homeExplorerYPosition
            }
          }
          footerCta: homeExplorerFooterCta {
            ctaButtonText: homeExplorerFooterCtaButtonText
            headline: homeExplorerFooterCtaHeadline
            paragraph: homeExplorerFooterCtaParagraph
          }
        }
      }
    }
  }
`
