import React, { useState } from 'react'
import tw from 'twin.macro'
import { H2, H3, H4} from './Headings';
import Container from './Container';
import Img from 'gatsby-image';

const NumberWrapper = tw.span`rounded-full h-8 w-8 bg-yellow-600 text-gray-900 font-bold text-xl text-center`;


const DescriptionItem = ({ headline, paragraph, numberLabel, highlighted }) => (
    <div 
        aria-current={(highlighted === numberLabel)}
        css={[
            tw`my-5 grid gap-4 p-3 rounded border-yellow-400 border-2 border-opacity-0 bg-white`,
            (highlighted === numberLabel) && tw`border-opacity-100`,
            `grid-template-columns: 2em 1fr`,
        ]}
    >
        <NumberWrapper>{numberLabel} </NumberWrapper>
        <div>
            <H3 tw="text-2xl text-blue-700">{headline}</H3>
            <p tw="text-gray-700 text-lg">{paragraph}</p>
        </div>
    </div>
)

const HomeLayerItem = (props) => {

    const { label, graphic, descriptionItems } = props;

    const [highlighted, setHightlighted] = useState(0); 
  
    // split descriptions into columns
    descriptionItems.map((item, numberLabel) => (Object.assign(item, { numberLabel: ++numberLabel })));
    const leftDescriptions = (descriptionItems.length > 1 ) 
        ? descriptionItems.slice(0, Math.floor(descriptionItems.length / 2)) 
        : descriptionItems;
    const rightDescriptions = (descriptionItems.length > 1 ) 
        ? descriptionItems.slice(Math.floor(descriptionItems.length / 2), descriptionItems.length) 
        : [];
  
    return (
        <section 
            aria-label={`${label} section`}
            {...props}
        >
            <Container>
                <div>
                    <H2 tw="text-center mb-10 text-4xl">{label}</H2>
                </div>
                <div tw="grid gap-12 row-gap-1 md:(grid-cols-2) lg:(grid-cols-4) relative">

                    {/* description columns */}
                    <div tw="flex flex-col content-center justify-center z-50">
                        {leftDescriptions.map((descriptionItem)=>(
                            <DescriptionItem {...descriptionItem} highlighted={highlighted} />
                        ))}
                    </div>
        
                    {/* Diagram */}
                    <div tw="md:(row-span-2) lg:(col-span-2 row-span-1) relative flex justify-center flex-col content-center">
                        <div tw="w-full">

                            <div tw="w-full relative mt-10">
                                <div tw="absolute z-50 w-full h-full">
                                    {/* Number Buttons */}
                                    {descriptionItems.map(({ numberLabel, headline, xPos, yPos }) => (
                                        <button 
                                        title={headline}
                                        aria-current={(highlighted === numberLabel)}
                                        css={[
                                            tw`absolute rounded-full`,
                                            `left: calc(${xPos ?? 50}% - 2rem);
                                            top: calc(${yPos ?? 50}% - 2rem);`,
                                        ]}
                                        onClick={() => setHightlighted(numberLabel)}
                                        >
                                            <NumberWrapper 
                                                css={[tw`h-8 w-8 px-3 pt-1 pb-2`,]} 
                                                >
                                                {numberLabel}
                                            </NumberWrapper>
                                        </button>
                                    ))}
                                </div>
                                {/* Diagram */}
                                <Img tw="relative" fluid={graphic.localFile.childImageSharp.fluid}/>
                            </div>

                            
                        </div>
                        
                    </div>
        
                    <div tw="flex flex-col content-center justify-center">
                        {rightDescriptions.map((descriptionItem)=>(
                            <DescriptionItem {...descriptionItem} highlighted={highlighted} />
                        ))}
                    </div>
        
                </div>
            </Container>
        </section>
    )
}

export default HomeLayerItem;